var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ErpIntegrationRun"}},[_c('v-container',{attrs:{"fluid":"","app":""}},[_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c('v-data-table',{staticClass:"elevation-1 text-no-wrap",staticStyle:{"width":"100%"},attrs:{"headers":_vm.apiResult.headers,"items":_vm.apiResult.items,"items-per-page":30,"footer-props":_vm.footerProps,"page":_vm.pagination.page,"item-key":_vm.getItemKey(),"search":_vm.search,"loading":_vm.loading,"loading-text":"Carregando dados...","height":_vm.noData ? '' : _vm.windowSize.y - 64 - 157,"fixed-header":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"pagination":_vm.updatePage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex align-start mr-4"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.tableIcon)+" ")])],1),_c('div',{staticClass:"d-flex flex-column align-start"},[_c('span',{staticClass:"text-h6 font-weight-regular"},[_vm._v(_vm._s(_vm.tableName))])]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-4",staticStyle:{"width":"1%"},attrs:{"append-icon":"mdi-magnify","label":_vm.$vuetify.lang.t('$vuetify.searchLabel'),"hide-details":"","single-line":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.createPermission)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"secondary mb-2 mr-4 pa-0",attrs:{"min-width":"48px","disabled":_vm.disableRunIntegrations,"loading":_vm.disableRunIntegrations},on:{"click":function($event){_vm.dialogRunIntegrations = true}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.disableRunIntegrations ? 'mdi-sync-off' : 'mdi-sync'))])],1)]}}],null,false,3495387916)},[_c('span',[_vm._v("Processar integrações")])]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogRunIntegrations),callback:function ($$v) {_vm.dialogRunIntegrations=$$v},expression:"dialogRunIntegrations"}},[_c('v-card',{staticClass:"pa-6"},[_c('div',{staticClass:"d-flex justify-start "},[_c('v-card-title',{staticClass:"headline text-center pa-0"},[_vm._v("Escolha o mês e ano para processar todas as integrações:")])],1),_c('div',{staticClass:"mt-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.picker,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.picker=$event},"update:return-value":function($event){_vm.picker=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-4",staticStyle:{"max-width":"400px"},attrs:{"value":_vm.formattedMonthYear,"label":"Mês/ Ano","prepend-icon":"mdi-calendar","readonly":""}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"primary","type":"month","no-title":"","scrollable":""},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.picker)}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogRunIntegrations = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.runningIntegrations},on:{"click":function($event){return _vm.runIntegrations()}}},[_vm._v("Confirmar")])],1)],1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mr-4 pa-0",attrs:{"color":"primary","min-width":"48px"},on:{"click":function($event){return _vm.refresh()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 pa-0",attrs:{"color":"primary","dark":"","min-width":"48px"},on:{"click":function($event){return _vm.closeTable()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])]),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogCloseTable),callback:function ($$v) {_vm.dialogCloseTable=$$v},expression:"dialogCloseTable"}},[_c('v-card',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-card-title',{staticClass:"headline text-center"},[_vm._v("Você possui edições não salvas. Tem certeza de que deseja fechar a tabela?")])],1),_c('v-card-actions',{staticClass:"px-6 pb-6"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogCloseTable = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("Confirmar")])],1)],1)],1)],1),_c('v-divider',{staticClass:"mx-4 mb-2"})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_vm._l((_vm.apiResult.headers),function(header,headerIndex){return [(header.value === 'status')?_c('td',{key:headerIndex,staticClass:"d-block d-sm-table-cell",staticStyle:{"width":"1%"}}):_c('td',{key:headerIndex,staticClass:"d-block d-sm-table-cell"},[(header.align === 'center')?_c('div',{staticClass:"d-flex justify-center"},[_c('span',[_vm._v(_vm._s(item[header.value]))])]):_c('div',{staticClass:"d-flex justify-start"},[_c('span',[_vm._v(_vm._s(item[header.value]))])])])]})],2)]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:"text-h6 primary--text"},[_vm._v(_vm._s(_vm.noDataMessage))])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }